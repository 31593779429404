import React from 'react';
import hands from "../images/search.png";

export default function SearchSection({ searchTerm, findMeme, chooseRandomTags, chooseRandom, hideMature, filterImagesByTag, showEverything, memesImages }) {
  return (
    <div style={{ display: "flex", justifyContent: "center", gap: ".25rem", alignItems: "center" }}>
      <div style={{ position: "relative" }}>
        <img
          style={{ position: "absolute", top: "-20px", left: "25px", cursor: "pointer" }}
          height={30}
          alt=""
          onClick={chooseRandomTags}
          src={hands}
        />
        <input
          value={searchTerm}
          onChange={(x) => findMeme(x.target.value)}
          placeholder={searchTerm ? searchTerm : "find a nub"}
          className="nubInput"
          style={{ zIndex: 2, maxWidth: "200px" }}
        />
      </div>

      <div style={{ display: "grid", gap: ".25rem" }}>
        <div style={{ display: "flex", gap: ".25rem" }}>
          <button onClick={chooseRandom} className="nubButton">
            random
            <div></div>
            meme
          </button>

          {hideMature ? (
            <button onClick={showEverything} style={{ padding: ".5rem", display: "grid" }} className="nubButton">
              show <div></div>18+
            </button>
          ) : (
            <button onClick={() => filterImagesByTag(memesImages)} style={{ padding: ".5rem", display: "grid" }} className="nubButton">
              hide <div></div>18+
            </button>
          )}
        </div>
      </div>
    </div>
  );
}