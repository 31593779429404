import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { searchMemes } from '../memeSearch2';
import { fetchMemes, incrementCopies, incrementDownloads, fetchFullMemes } from '../server2';
import static1 from "../images/static.gif";
import mascot from "../images/mascot.png";
import MemesGrid from './MemesGrid';
import ImageSlider from './ImageSlider';
import RandomMeme from './RandomMeme';
import TagsSection from './TagsSection';
import SearchSection from './SearchSection';
import { saveAs } from 'file-saver';

const explicitTags = [
  "18+", "nsfw", "adult", "xxx", "porn", "sexual", "explicit",
  "nudity", "erotic", "sex", "mature", "r-rated", "x-rated",
  "hentai", "ecchi", "lewd", "kinky", "fetish", "bdsm"
];

export default function NubBeta(props) {
  const [memesImages, setMemesImages] = useState([]);
  const [imageCopied, setImageCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTags, setActiveTags] = useState([]);
  const [hideMature, setHideMature] = useState(false);
  const [viewRandom, setViewRandom] = useState(false);
  const [randomImage, setRandomImage] = useState("");
  const [previousImage, setPreviousImage] = useState("");
  const gridRef = useRef(null);

  async function chooseRandomTags() {
    let memeTags = [];
    const memesArray = await fetchMemes();

    for (let i = 0; i < memesArray.length; i++) {
      memesArray[i].tags.forEach((tags) => {
        memeTags.push(tags)
      })
    }

    const memeTagsLoaded = await Promise.all(memeTags)
    if (hideMature === true) {
      const newImages = memeTagsLoaded.filter(tag => !tag.includes("18+"));
      const memeTags1 = newImages[Math.floor(Math.random() * newImages.length)];
      await findMeme(memeTags1);
    } else {
      const memeTags1 = memeTagsLoaded[Math.floor(Math.random() * memeTagsLoaded.length)];
      await findMeme(memeTags1);
    }
  }

  async function filterMostDownloads() {
    let memesArray = [];
    const memes = await fetchMemes();
    for (let i = 0; i < memes.length; i++) {
      memesArray.push(memes[i])
    }
    const mostDownloads = memesArray.sort(function (a, b) { return b.downloads - a.downloads });
    const totalDownloads = await Promise.all(mostDownloads);
    setMemesImages(totalDownloads);
  }

  async function filterMostCopied() {
    let memesArray = [];
    const memes = await fetchMemes();
    for (let i = 0; i < memes.length; i++) {
      memesArray.push(memes[i])
    }
    const mostCopied = memesArray.sort(function (a, b) { return b.copied - a.copied });
    const totalDownloads = await Promise.all(mostCopied);
    setMemesImages(totalDownloads)
  }

  async function findMeme(tag) {
    const memesFound = await searchMemes(tag);
    setMemesImages([]);
    if (hideMature === true) {
      const newImages = memesFound.filter(image => !image.tags.includes("18+"));
      setMemesImages(newImages);
      setSearchTerm(tag);
      setViewRandom(false);
    } else {
      setSearchTerm(tag);
      setMemesImages(memesFound);
      setViewRandom(false);
    }
  }

  const handleDownloadImage = async (imageUrl, name, type, id) => {
    try {
      if (type === "video/mp4") {
        const response = await fetch(imageUrl, { responseType: 'blob' });
        const blob = await response.blob();
        saveAs(blob, `${name}.mp4`);
      } else if (type === "image/jpg") {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, `nub_meme_${name}.jpg`); // Adjust filename as needed
      } else if (type === "image/gif") {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, `nub_meme_${name}.gif`); // Adjust filename as needed
      } else if (type === "image/jpeg") {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, `nub_meme_${name}.jpeg`); // Adjust filename as needed
      } else if (type === "image/png") {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, `nub_meme_${name}.jpeg`); // Adjust filename as needed
      }
      incrementDownloads(id)
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleImageFetch = async (imageUrl, id) => {
    try {
      await toast('Nub copied!', {
        icon: `${mascot}`
      });

      const response = await fetch(imageUrl);
      const blob = await response.blob(); // Fetch the image as a Blob

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const image = new Image();
      image.crossOrigin = "anonymous"; // May or may not work
      image.onload = async () => { // Process after image loads
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        canvas.toBlob((newBlob) => {
          navigator.clipboard.write([
            new ClipboardItem({ [newBlob.type]: newBlob })
          ]).then(() => {
            setImageCopied(!imageCopied)
            console.log('Image copied to clipboard!');
          }).catch(error => {
            console.error('Error copying image:', error);
          });
        });
      }
      image.src = imageUrl;
      incrementCopies(id)// Start loading the image
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  function filterImagesByTag(images) {
    const newImages = images.filter(image =>
      !image.tags.some(tag => explicitTags.includes(tag.toLowerCase()))
    );
    setHideMature(!hideMature);
    setMemesImages(newImages);
  }

  async function showEverything() {
    const images = await fetchMemes();
    setHideMature(!hideMature);
    setMemesImages(images);
  }

  async function chooseRandom() {
    const images = await fetchMemes()
    setSearchTerm("")
    setPreviousImage(randomImage)

    let image = {
      source: static1,
      type: "image/gif"
    };

    setRandomImage(image)

    setViewRandom(true);
    setTimeout(() => {
      const randomImage = images[Math.floor(Math.random() * images.length)];
      setRandomImage(randomImage)
    }, 1000)
  }

  async function showPrevious() {
    let image = {
      source: static1,
      type: "image/gif"
    };

    setRandomImage(image)

    if (!previousImage) {
      const images = await fetchMemes()
      setSearchTerm("")
      setPreviousImage(randomImage)

      let image = {
        source: static1,
        type: "image/gif"
      };

      setRandomImage(image)

      setViewRandom(true);
      setTimeout(() => {
        const randomImage = images[Math.floor(Math.random() * images.length)];
        setRandomImage(randomImage)
      }, 1000)
      return;
    }

    setTimeout(() => {
      setRandomImage(previousImage)
    }, 1000)
  }

  useEffect(() => {
    async function getMemes() {
      // const images = await fetchMemes();
      filterMostDownloads()
      fetchFullMemes()
      // setMemesImages(images);
    }
    getMemes()
  }, [])

  useEffect(() => {
    const memeTags = memesImages.reduce((tags, meme) => {
      if (Array.isArray(meme.tags)) {
        tags.push(...meme.tags);
      }
      return tags;
    }, []);

    const uniqueWords = [...new Set(memeTags)];
    setActiveTags(uniqueWords);
  }, [memesImages])

  useEffect(() => {
    async function refreshMemes() {
      if (props.refresh === true) {
        props.setRefresh(false)
        setSearchTerm("")
        setHideMature(false)
        setViewRandom(false)
        filterMostDownloads()
      }
    }
    refreshMemes()
  }, [props?.refresh])

  async function loadMoreMemes() {
    if (!searchTerm) {
      const newMemes = await fetchMemes();
      setMemesImages(prevMemes => [...prevMemes, ...newMemes]);
    }
  }

  useEffect(() => {
    if (!searchTerm) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreMemes();
          loadMoreMemes();
          loadMoreMemes();
        }
      });

      const target = document.querySelector('#load-more-trigger');
      if (target) {
        observer.observe(target);

        // Check if the target is already visible when mounted
        if (target.getBoundingClientRect().top <= window.innerHeight) {
          loadMoreMemes();
          loadMoreMemes();
          loadMoreMemes();
        }
      }

      return () => {
        if (target) {
          observer.unobserve(target);
        }
      };
    }
  }, [searchTerm]);

  return (
    <div>
      <ToastContainer
        icon={mascot}
        hideProgressBar={true}
        closeButton={false}
        autoClose={800}
        toastStyle={{
          color: "white",
          background: "#9ecdff",
          fontFamily: "Poppins"
        }}
      />

      <SearchSection
        searchTerm={searchTerm}
        findMeme={findMeme}
        chooseRandomTags={chooseRandomTags}
        chooseRandom={chooseRandom}
        hideMature={hideMature}
        filterImagesByTag={filterImagesByTag}
        showEverything={showEverything}
        memesImages={memesImages}
      />

      {viewRandom && (
        <RandomMeme
          randomImage={randomImage}
          showPrevious={showPrevious}
          handleDownloadImage={handleDownloadImage}
          chooseRandom={chooseRandom}
        />
      )}

      {activeTags.length > 0 && !viewRandom && (
        <TagsSection activeTags={activeTags} findMeme={findMeme} />
      )}

      {!viewRandom && memesImages.length > 0 && (
        <ImageSlider gridRef={gridRef} />
      )}

      <MemesGrid
        handleDownloadImage={handleDownloadImage}
        viewRandom={viewRandom}
        gridRef={gridRef}
        memesImages={memesImages}
        handleImageFetch={handleImageFetch}
      />

      <div id="load-more-trigger" style={{ height: '1px' }}></div>
    </div>
  );
}