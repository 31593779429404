import React from 'react';
import { motion } from "framer-motion";

export default function TagsSection({ activeTags, findMeme }) {
  return (
    <div style={{ display: "flex", justifyContent: "center", paddingBottom: "2.5rem" }}>
      <motion.div
        animate={{ y: 25 }}
        transition={{ type: "spring", stiffness: 100 }}
        viewport={{ once: false }}
        className="tagsSection"
      >
        {activeTags.map((tag, index) => (
          <motion.button onClick={() => findMeme(tag)} key={index} className="nubTagButton">
            {tag}
          </motion.button>
        ))}
      </motion.div>
    </div>
  );
}