import { totalMemes } from "./server";
// import { memeArrayAI } from "./server";

export async function searchMemes(tag) {
  // Fetch memes from the server using the provided tag
  const response = await fetch(`https://new-nub-server-production.up.railway.app/memesai/tag/${tag}`);
  const memesData = await response.json();

  let memesFound = [];
  for (let i = 0; i < memesData.length; i++) {
    memesData[i].tags.forEach(async (data) => {
      if (data.includes(tag.toLowerCase())) {
        const alreadyAdded = memesFound.filter((source) => source.source === memesData[i].source)

        let memeDetails = {
          source: memesData[i]?.source,
          tags: memesData[i]?.tags,
          type: memesData[i]?.type,
          downloads: memesData[i]?.downloads,
          copied: memesData[i]?.copied,
          id: memesData[i]?.id,
          matchScore: 0
        }

        memeDetails.matchScore = memesData[i].tags.filter(t => t.includes(tag.toLowerCase())).length;

        if (alreadyAdded.length === 0) {
          memesFound.push(memeDetails)
        }
      }
    })
  }

  memesFound.sort((a, b) => b.matchScore - a.matchScore);

  const dataFound = await Promise.all(memesFound);
  return dataFound;
}