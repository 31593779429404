import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, updateDoc, doc, increment, addDoc, deleteDoc, getCountFromServer } from "firebase/firestore";
import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyABogr6mLaiIh2lPv2j8pbThtn3Yxr_Cp4",
  authDomain: "nubmemes-b9c6b.firebaseapp.com",
  projectId: "nubmemes-b9c6b",
  storageBucket: "nubmemes-b9c6b.appspot.com",
  messagingSenderId: "185323050746",
  appId: "1:185323050746:web:1b620f985a2ef44c509b43"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const databaseSection = "memes"

export const memeArray = [];
export const totalMemes = [];
export const memeArrayAI = [];

let fetchMemesCallCount = 0;

export async function fetchMemes() {
  fetchMemesCallCount++;
  const page = fetchMemesCallCount;
  const limit = 5;

  let memeArray = [];

  await axios.get(`https://new-nub-server-production.up.railway.app/memesai?page=${page}&limit=${limit}`).then((data) => {
    for (let i = 0; i < data.data.memes.length; i++) {
      memeArray.push({
        source: data.data.memes[i].source,
        tags: data.data.memes[i].tags,
        type: data.data.memes[i].type,
        id: data.data.memes[i].id,
        downloads: data.data.memes[i]?.downloads ? data.data.memes[i].downloads : 0,
        copied: data.data.memes[i]?.copied ? data.data[i].copied : 0
      });
    }
  });

  const dataReturn = await Promise.all(memeArray);
  return dataReturn;
}

export async function fetchFullMemes() {
  if (totalMemes.length > 0) {
    return totalMemes;
  }

  await axios.get(`https://new-nub-server-production.up.railway.app/memeslist`).then((data) => {
    console.log("Data: ", data.data)
    for (let i = 0; i < data.data.length; i++) {
      totalMemes.push({
        source: data.data[i].source,
        tags: data.data[i].tags,
        type: data.data[i].type,
        id: data.data[i].id,
        downloads: data.data[i]?.downloads ? data.data[i].downloads : 0,
        copied: data.data[i]?.copied ? data.data[i].copied : 0
      });
    }
  });

  const dataReturn = await Promise.all(memeArray);
  return dataReturn;
}

export async function fetchMemesAI() {
  if (memeArrayAI.length > 0) {
    return memeArrayAI;
  }

  await axios.get("https://solanapulseserver-production.up.railway.app/memesai").then((data) => {
    for (let i = 0; i < data.data.length; i++) {
      memeArrayAI.push({
        source: data.data[i].source,
        tags: data.data[i].tags,
        type: data.data[i].type,
        id: data.data[i].id,
        downloads: data.data[i]?.downloads ? data.data[i].downloads : 0,
        copied: data.data[i]?.copied ? data.data[i].copied : 0
      });
    }
  });

  const dataReturn = await Promise.all(memeArrayAI)
  return dataReturn;
}

let incremented;
export async function incrementDownloads(id) {
  if (incremented === true) return;
  incremented = true;

  try {
    const documentRef = doc(db, databaseSection, id);

    await updateDoc(documentRef, {
      downloads: increment(1)
    });

    setTimeout(() => {
      incremented = false;
    }, 10000)
    console.log("Downloads incremented successfully!");
  } catch (error) {
    console.error("Error updating downloads:", error);
  }
}

let copied;
export async function incrementCopies(id) {
  if (copied === true) return;
  copied = true;

  try {
    const documentRef = doc(db, databaseSection, id);

    await updateDoc(documentRef, {
      copied: increment(1)
    });

    setTimeout(() => {
      copied = false;
    }, 10000)
    console.log("Downloads incremented successfully!");
  } catch (error) {
    console.error("Error updating downloads:", error);
  }
}

export async function addDocument(tags, image) {
  const docRef = await addDoc(collection(db, databaseSection), {
    source: image,
    tags: tags,
    type: "image/gif",
    downloads: 0,
    copied: 0
  });

  // alert("Silly gif has been added!")
  console.log("Document written with ID: ", docRef.id);
}

export async function deleteMeme(id) {
  await deleteDoc(doc(db, databaseSection, id));
  await alert("Meme deleted!");
}

export async function updateDocument(id, tags) {
  let newTags = tags.split(" ");
  if (newTags.length > 0) {
    try {
      const documentRef = doc(db, databaseSection, id);
      await updateDoc(documentRef, {
        tags: newTags
      });

      alert(`Tags updated: ${tags}`);
    } catch (error) {
      console.error("Error updating tags: ", error);
    }
  }
}