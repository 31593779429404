import React from 'react';
import { motion } from "framer-motion";
import tv from "../images/tv.png";
import static1 from "../images/static.gif";
import saveButton from "../images/save button.png";
import rightArrow from "../images/next button.png";
import leftArrow from "../images/previous button.png";

export default function RandomMeme({ randomImage, showPrevious, handleDownloadImage, chooseRandom }) {
  return (
    <div className="image-container">
      {randomImage.type !== "video/mp4" ? (
        <>
          <img alt="" src={randomImage.source} className="base-image" />
          <img alt="" src={tv} className="tv-overlay" />
        </>
      ) : (
        <>
          <video autoPlay={true} controls={true} loop={true} muted={false} src={randomImage.source} className="base-image" />
          <img alt="" src={tv} className="tv-overlay" />
        </>
      )}

      <div className="tv-button">
        <motion.button
          whileHover={{ scale: 1.0 }}
          whileTap={{ scale: 0.9 }}
          onClick={showPrevious}
          style={{ background: "transparent", border: "transparent", zIndex: 2 }}
        >
          <img alt="" className="arrow-button left" src={leftArrow} />
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.0 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => handleDownloadImage(randomImage.source, randomImage.tags[0], randomImage.type, randomImage.id)}
          style={{ background: "transparent", border: "transparent", zIndex: 2 }}
        >
          <img alt="" className="save-button" src={saveButton} />
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.0 }}
          whileTap={{ scale: 0.9 }}
          onClick={chooseRandom}
          style={{ background: "transparent", border: "transparent", zIndex: 2 }}
        >
          <img alt="" className="arrow-button right" src={rightArrow} />
        </motion.button>
      </div>
    </div>
  );
}